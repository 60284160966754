<template>
    <div class="container px-0">
        <div class="container-fluid bg-lightblue px-0 py-0">

            <!-- current paymentMethod -->
            <div class="row">
                <div class="col-12">
                    <div class="container py-0 px-0">
                        <div class="row">
                            <div class="col-12">
                                <span class="mp-rbk mp-font-weight-semi-bold font-size-expert-register-payment-title d-block text-center">Dein aktuelles Zahlungsmittel</span>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-12">
                                <template v-if="pUserPaymentMethod.type === 'paypal'">
                                    <img src="../assets/img/paypal_logo.svg" width="60">
                                    <span style="font-size: 18px; font-weight: bold; padding-left: 10px">PAYPAL</span>
                                </template>
                                <template v-else>
                                    <b>Kartentyp:</b> {{ pUserPaymentMethod.card.brand }}<br>
                                    <b>Kartennummer (die letzten 4 Stellen):</b> {{ pUserPaymentMethod.card.last4 }}<br>
                                    <b>Gültig bis:</b> {{ pUserPaymentMethod.card.exp_month }}/{{ pUserPaymentMethod.card.exp_year }}<br>
                                </template>
                            </div>
                            <!-- btn: change -->
                            <div class="col-3 mt-5" v-if="!showChangePaymentMethodElement">
                                <a class="btn btn-mp-green-subscribe mp-qks mp-font-weight-medium mt-3 w-100"
                                   style="margin-right: 10px"
                                   @click="buildStripeElement"
                                >Zahlungsmittel ändern</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- paymentMethod: data collection -->
            <div class="row" v-if="showChangePaymentMethodElement">
                <div class="col-12 col-lg-7 mt-5">
                    <!-- paymentElement -->
                    <div v-if="!changePaymentMethodReady" style="font-size: 1.2rem; font-weight: bold; margin-top: 5px;">
                        Einen Moment bitte...
                    </div>

                    <div>
                        <b>Bitte gib hier dein neues Zahlungsmittel an</b>
                    </div>

                    <div class="mt-5" style="border: 1px solid dimgrey; border-radius: 0.5rem; padding: 10px">
                        <form id="change-payment-method-form" :aria-disabled="changePaymentMethodInProgress">
                            <div id="change-payment-method-element" ref="change-payment-method-element">
                                <!-- Elements will create form elements here -->
                            </div>
                            <div id="error-message">
                                {{ changePaymentMethodErrorMsg }}
                            </div>
                        </form>
                    </div>
                </div>

                <div v-if="changePaymentMethodInProgress" class="col-12 mt-5 text-left" style="font-size: 1.2rem; font-weight: bold;">
                    Bestätigung des Zahlungsmittels läuft, einen Moment bitte...
                </div>

                <div v-else class="col-12 mt-5 text-left">
                    <div class="row">
                        <div class="col-12 col-lg-3">
                            <a class="btn btn-mp-red-subscribe mp-qks mp-font-weight-medium mt-3 w-100"
                               style="margin-right: 10px"
                               @click="cancel"
                            >Abbrechen</a>
                        </div>
                        <div class="col-12 col-lg-4">
                            <a class="btn btn-mp-green-subscribe mp-qks mp-font-weight-medium mt-3 w-100"
                               style="margin-right: 10px"
                               @click="confirm"
                            >Neues Zahlungsmittel bestätigen</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Toast v-if="showToast" :title="toastTitle" :msg="toastMsg" :background-color="toastBgColor" :delay="10000" @close="showToast = false" />
    </div>
</template>



<script>
    import {apiCall, buildFinalUrl} from "../helpers";
    import Configs from "/src/configs";
    import Toast from "/src/components/Toast";
    import {mixinBasics} from "../mixins/basics";

    const api = Configs.restApi.endpoints.map;
    let stripeElements = undefined;


    export default {
        name: "PaymentMethodChange",

        mixins: [
            mixinBasics
        ],

        components: {
            Toast
        },

        props: {
            pUserPaymentMethod: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                userPaymentMethod: undefined,
                showChangePaymentMethodElement: false,
                changePaymentMethodReady: false,
                changePaymentMethodInProgress: false,
                changePaymentMethodErrorMsg: undefined,

                showToast: false,
                toastTitle: undefined,
                toastMsg: undefined,
                toastBgColor: undefined,
            };
        },

        methods: {
            // ------------------------------------------------- PaymentMethod related stuff
            async createPaymentMethodSetupIntent() {
                console.log('createPaymentMethodSetupIntent');
                let req = api.subscription.paymentMethod.setupIntent.create;
                let res = await apiCall(req.url, req.method);
                return res.data;
            },

            async buildStripeElement() {
                this.showChangePaymentMethodElement = true;
                this.intent = await this.createPaymentMethodSetupIntent();
                console.log('intent:'); console.log(this.intent);
                stripeElements = await this.createStripeElements();
                const paymentElem = await stripeElements.create('payment').on('ready', () => { console.log('>> PaymentElem rdy.'); this.paymentReady = true; });
                paymentElem.mount(this.$refs["change-payment-method-element"]);
            },

            async cancel() {
                console.log('cancelPaymentMethodSetupIntent');
                let req = api.subscription.paymentMethod.setupIntent.cancel;
                await apiCall(buildFinalUrl(req.url, {id: this.intent.id}), req.method);
                this.showChangePaymentMethodElement = false;
            },

            async confirm() {
                console.log('confirmPaymentMethodSetupIntent');
                this.changePaymentMethodInProgress = true;
                this._stripe.confirmSetup({
                    elements: stripeElements,
                    confirmParams: {
                        return_url: Configs.paypal[Configs.applicationMode].return_url
                    },
                    redirect: 'if_required'
                })
                    .then(async (res) => {
                        this.debugDummy = res;
                        console.log(res);
                        let resCheck = await this.storeUserPaymentMethod(res);
                        if (resCheck.status === 'requires_confirmation' || resCheck.status === 'requires_action') {
                            res = await this._stripe.confirmSetup(resCheck.client_secret);
                            console.log('confirmSetup (result):');
                            console.log(res);
                            if (res.error) {
                                this.changePaymentMethodInProgress = false;
                                this.changePaymentMethodErrorMsg = res.error.message;
                                this.showInfoToast('Zahlungsmittel', 'Änderungen war leider nicht erfolgreich.', '#842029');
                                return;
                            }
                        }
                        setTimeout(async () => {
                            this.$emit('update');
                            this.showChangePaymentMethodElement = false;
                            this.changePaymentMethodReady = false;
                            this.changePaymentMethodInProgress = false;
                            this.showInfoToast('Zahlungsmittel', 'Änderungen war erfolgreich!');
                        }, 6000);
                });
            },

            async storeUserPaymentMethod(res) {
                let req = api.subscription.paymentMethod.update;
                return (await apiCall(req.url, req.method, {setup_intent_id: this.intent.id, payment_method_id: res.setupIntent.payment_method})).data;
            },

            async createStripeElements() {
                return await this._stripe.elements({'clientSecret': this.intent.client_secret});
            },

            showInfoToast(title, msg, backBgColor = undefined) {
                this.toastTitle = title;
                this.toastMsg = msg;
                this.toastBgColor = backBgColor;
                this.showToast = true;
            },
        },
    }
</script>



<style scoped>

</style>